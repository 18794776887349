import React from 'react';
import logo from './assets/logo.svg';

import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
      <h1>Div Int Software</h1>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
